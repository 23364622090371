body {
  margin: 0;
  font-family: "Bodoni_72", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @font-face {
  font-family: "Wildy_Sans";
  src: local("Wildy_Sans"), url(./fonts/Wildy_Sans.otf), format("opentype")
} */
@font-face {
  font-family: "Bodoni_72";
  src: local("Bodoni_72"), url(./fonts/Bodoni_72.ttf), format("truetype")
}

