.Button {
    /* font-family: "Wildy Sans"; */
    cursor: pointer;
    padding: 1em;
    color: forestgreen;
}

.Navbar {
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 6fr;
    padding: 1em;
}

.links-area {
    display: flex;
    align-items: flex-end;
}